import React from "react"
import { Translate } from "react-redux-i18n"
import {
  ClearAllButton as UIClearAllButton,
  FilterButton as UIFilterButton
} from "shared/ui/berry-jass"

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const ClearAllButton = (props) => {
  return (
    <UIClearAllButton {...props}>
      <Translate value="clear-all" />
    </UIClearAllButton>
  )
}

/**
 * @param {import("@mui/material").ButtonProps} props
 */
export const FilterButton = (props) => {
  return (
    <UIFilterButton {...props}>
      <Translate value="filter" />
    </UIFilterButton>
  )
}
